import { listPublicPlans } from '@wix/ambassador-pricing-plans-v2-plan/build/cjs/http.impl';
import { PublicPlan } from '@wix/ambassador-pricing-plans-v2-plan/types';
import { PlanListWidgetRole } from '../../constants/elements';
import { plansFixtureTranslated } from '../../fixtures';
import { toError } from '../../utils/errors';
import model from './model';

enum WidgetState {
  Empty = PlanListWidgetRole.EmptyState,
  PlanInfo = PlanListWidgetRole.PlansInfoState,
  Loading = PlanListWidgetRole.LoadingState,
  Error = PlanListWidgetRole.ErrorState,
}

export default model.createController(({ $w, $widget, flowAPI }) => {
  const getDemoPlansIfEditor = () => {
    return flowAPI.environment.isEditor ? plansFixtureTranslated(flowAPI.translations.t) : null;
  };

  const getPlans = async (planIds?: string[]) => {
    if (!planIds?.length) {
      return getDemoPlansIfEditor();
    }

    const response = await flowAPI.httpClient.request(listPublicPlans({ planIds }));
    return response.data.plans ?? getDemoPlansIfEditor();
  };

  const showState = (state: WidgetState) => {
    $w(`#${PlanListWidgetRole.PlanListStates}`).changeState(state as unknown as string);
  };

  const setWidgetData = (plans: PublicPlan[]) => {
    $w(`#${PlanListWidgetRole.PlanList}`).onItemReady(($item, itemData) => {
      $item('#plan1').setPlan(itemData.plan);
    });

    $w(`#${PlanListWidgetRole.PlanList}`).data = plans.map((plan, i) => ({
      _id: 'plan-' + i,
      plan,
    }));

    showState(WidgetState.PlanInfo);
  };

  return {
    pageReady: async () => {
      if (!flowAPI.environment.isEditor && !flowAPI.environment.isSSR) {
        showState(WidgetState.Loading);
      }

      try {
        const { props } = $widget;
        const plans = await getPlans(props.planIds ? props.planIds.split(',') : undefined);
        if (!plans) {
          return showState(WidgetState.Empty);
        }

        setWidgetData(plans);
      } catch (e) {
        showState(WidgetState.Error);
        flowAPI.errorMonitor?.captureException(toError(e));
      }
    },
    exports: {},
  };
});
